.primary-button {
  background: #00504e;
  color: #fff;
}
.primary-button:hover {
  background: #003635;
  color: #fff;
}
.secondary-button {
  background: #c7c7c7;
  color: #fff;
}
.secondary-button:hover {
  background: #a1a1a1;
  color: #fff;
}
.let_me_in_btn_trnsparent {
  background-color: rgba($color: #fff, $alpha: 0.8);
  font-weight: 600;
  color: #003635;
}
.let_me_in_btn_trnsparent:hover {
  color: #fff;
  background-color: #003635;
}
.react-datepicker {
  border: 0 !important;
  .react-datepicker__current-month {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  .react-datepicker__header {
    background-color: transparent;
  }
  .react-datepicker__current-month {
    font-size: 1rem;
    margin-bottom: 0.875em;
  }
  .react-datepicker__navigation {
    top: 6px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #964b00;
    color: #fff;
  }
  .react-datepicker__day-name {
    color: #555;
    font-size: 0.65rem;
  }
}
.form-modal {
  .modal-header {
    border: 0;
  }
  .modal-title {
    color: #00504e;
  }
  .modal-content {
    padding: 1.5em;
  }
}
.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamdonep: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.interested_users {
  background-color: #003635;
  display: flex;
  flex-direction: row;
//   justify-content: center;
  align-items: center;
  height: 200px;
  padding: 15px;
}

.users_image img {
  height: 50px;
  width: 50px;
  border: 100%;
}
.interested_user_name{
    color: #fff;
}
