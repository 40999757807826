
@import './Variables';




@media screen and (max-width: 767px) {
    .building_stellar{
        background-size: 100% 100%;
    }

    .building_stellar_bg h3,
    .building_stellar_bg ul li
    // .building_stellar_form h4,
    // .building_stellar_form p,
    // .building_stellar_form
    {
        color: $brand_white;
    }

    .building_stellar_form .form-control{
        background-color: $brand_white;
        border: 1.5px solid  $brand_white;

    }
    .coach_details_banner::before{
        height: 175px;
    }
    .coach_details_banner_content .coach_details_img{
        width: 150px;
        height: 150px;
    }


}



@media screen and (max-width: 500px) {
    .navbar-brand img{
        width: 120px;
    }
    .d-grid .btn{
        padding-left: 10px;
        padding-right: 10px;
    }
    .register_create_start .rcs_img {
        width: 50px;
        height: 50px;
        padding: 5px;
    }
    .register_create_start ul{
        padding: 0;
    }
    
    .register_create_start ul li:nth-child(odd){
        margin-right: 0px;
    }
    .register_create_start ul li:nth-child(even){
        margin-left: 0px;
    }
    .register_create_start .rcs_img img{
        width: 30px;
        height: 30px;
    }
    .my_light_modal{
        padding: 0;
    }
    .coach_details_banner::before{
        height: 150px;
    }
    .coach_details_banner_content .coach_details_img{
        width: 120px;
        height: 120px;
    }


}





@media screen and (max-height: 1200px) {
    .modal .ls_modal_conatiner{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .signup_grn_card_content{
        margin: 20px auto;
    }

    .coach_details_img {
        max-height: 300px;
        max-width: 300px;
    }
}
@media only screen and (max-width: 1024px) {
    .home_banner{
        padding: 50px 0px;
    }
}

@media only screen and (max-width: 700px) {
    .coach_testimonials h3,
    .building_stellar_bg h3,
    .fs-50{
        font-size: 32px !important;
    }
    .home_banner{
        padding: 30px 0px;
    }
    .home_banner h2{
        font-size: 50px;
    }
    .banner_total_counter h3{
        font-size: 28px;
    }
    .banner_total_counter h4{
        font-size: 20px;
    }
    .hdng_a h2{
        font-size: 32px;
    }
    .brand_bg_color2 .hdng_a h2{
        font-size: 32px;
    }
    .top_coach_experienced{
        padding: 20px 0;
    }
    .building_stellar_bg{
        padding: 30px 10px;
    }
}



@media only screen and (max-width: 500px) {
    .fs-18{
        font-size: 14px !important;
    }
    .fs-28{
        font-size: 20px !important;
    }
    .fs-40{
        font-size: 32px !important;
    }
    .home_banner h2{
        font-size: 32px;
    }
    .best_coach_card{
        margin: 10px auto;
    }
    .coach_testimonials p{
        margin: 10px auto;
    }
    .coach_testimonials h4{
        font-size: 32px;
        margin: 0;
    }
    .tab_nav_link .nav-item .nav-link{
        color: $brand_text_color4;
        font-weight: 500;
        font-size: 12px;
        padding: 10px 7px;
    }
    .pic_labels label,
    .browse_coach_list_card_header label,
    .browse_coach_suggestion_list_card_header label,
    .coach_details_label label,
    .asf_card_header label,
    .dot_lable label{
        font-size: 12px;
        margin-right: 8px;
        padding-left: 8px;
    }
    .btn-sm{
        height: 40px !important;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        font-size: 12px;
    }
}



.coach_details_mob_ {
    .hdng_a h2{
        font-size: 20px;
    }
    .hdng_a h6{
        font-size: 12px;
    }
    .coach_details_testimonial_card{
        display: block;
    }
    .coach_details_testimonial .coach_details_testimonial_card_img img{
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next,
    .owl-carousel .owl-nav button.owl-prev:hover,
    .owl-carousel .owl-nav button.owl-next:hover,
    .owl-theme .owl-nav [class*='owl-']:hover{
        background-color: transparent !important;
        background-repeat: no-repeat;
        background-position: center;
    }
    .owl-carousel .owl-nav button.owl-prev{
        left: -15px;
        background-size: 8px;
        background-image: url('../images/icons/left_arrow.png');
    }
    .owl-carousel .owl-nav button.owl-next{
        right: -15px;
        background-size: 8px;
        background-image: url('../images/icons/right_arrow.png');
    }
    .owl-carousel .owl-nav button.owl-prev span,
    .owl-carousel .owl-nav button.owl-next span{
        display: none;
    }
    .coach_details_testimonial .owl-carousel .owl-item 
    .coach_details_testimonial_card p{
        font-size: 14px !important;
        font-weight: 400 !important;
        opacity: 0.6;
    }
    .coach_details_testimonial .owl-carousel .owl-item .coach_details_testimonial_card{
        border: 0px;
    }
    .coach_details_testimonial .owl-carousel .owl-item .coach_details_testimonial_card{
        margin-bottom: 0;
    }
}