.booking_user_card {
    /* Default styles for the card */
    border: 1px solid transparent; /* Default border */
    transition: border 0.3s ease; /* Smooth transition for the border */
}

.booking_user_card.selected {
    border: 2px solid #00504E; /* Change to the desired border color and thickness */
}

.check-box-btn {
    padding: 10px; /* Add padding for better appearance */
    border: 1px solid transparent; /* Default border */
    border-radius: 5px; /* Optional: rounded corners */
    cursor: pointer; /* Change cursor to pointer for better UX */
    transition: border-color 0.3s ease; /* Smooth transition for the border */
}

.check-box-btn.selected {
    border: 2px solid #00504E; /* Change to the desired border color when selected */
}

