@import '../../../assets/scss/Variables';

footer{
    background: $brand_bg_color2;
    padding-top: 20px;
}
footer hr{
    // margin: 30px auto;
    border-color: #ffffff;
}
.footer_details p{
    color: $brand_white;
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;
}
.footer_details,
.menu_link{
    margin-top: 20px;
    margin-bottom: 20px;
}
.menu_link h4{
    font-weight: 600;
    font-size: 22px;
    color: $brand_white;
    margin-bottom: 20px;
}
.menu_link a{
    display: block;
    color: $brand_white;
    font-weight: 400;
    font-size: 14px;
    margin: 10px 0;
}
.menu_link a:hover{
    color: $brand_bg_color1;
    opacity: 0.5;
}
.social_link a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 1px solid $brand_white;
    margin-right: 15px;
    border-radius: 50%;
}
.social_link a:hover{
    background: $brand_white;
}
.social_link a:hover svg path{
    fill: $brand_bg_color2;
}
.social_link a:hover svg.feather-instagram path{
    fill: $brand_white;
}
.social_link a:hover svg.feather-instagram path,
.social_link a:hover svg.feather-instagram rect{
    stroke: $brand_bg_color2;
}
.copyright p{
    // color: $brand_white;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}
.play_store a{
    margin-left: 15px;
}
.copyright{
    background-color: $brand_bg_color1;
    padding-top: 5px;
    padding: 25px;
  }

@media only screen and (max-width: 700px) {
    footer{
        padding-top: 20px;
    }
    .footer_details, .menu_link{
        margin: 10px auto;
    }
    .menu_link a{
        // display: inline-block;
        margin-right: 10px;
        margin-top: 0px;
    }
}