.booking-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .booking-container h1 {
    text-align: center;
  }
  
  .booking-container .date-selectors,
  .booking-container .dropdowns {
    margin: 20px 0;
  }
  
  .booking-container label {
    display: block;
    margin-bottom: 10px;
  }
  
  .booking-container input[type="date"], .booking-container
  select {
    margin-left: 10px;
  }
  